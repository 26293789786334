import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import throttle from 'lodash/throttle';
import { DebouncedFuncLeading } from 'lodash';
import { DNABox, DNAButton, DNAText } from '@alucio/lux-ui';
import DNAError from 'src/components/DNA/Error';
import DNASchedulePublishDocument from 'src/components/DNA/Modal/DNASchedulePublishDocument';
import { useInterpret } from '@xstate/react';
import { useDispatch } from 'src/state/redux';
import useLazyRef from 'src/hooks/useLazyRef';
import useMachineSelector, { composite } from 'src/hooks/useSelector';
import useContentPageData, { ContentPageData, Recommendations } from 'src/hooks/useContentPageData/useContentPageData';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
import { MAX_OFFLINE_FILE_SIZE_BYTES } from 'src/worker/machines/sync/syncEntry';
import {
  AssociatedFile,
  AttachedFile,
  ConversionStatus,
  ConversionWarningCode,
  FileType,
} from '@alucio/aws-beacon-amplify/src/models';
import { DocumentORM, DocumentVersionORM, ORMTypes } from 'src/types/types';
import { getPresentable } from 'src/state/context/ContentProvider/helper';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import { UploadStatus } from 'src/components/DNA/FileUpload/FileUpload';
import { multiSliceActions } from 'src/state/redux/slice/multiSlice';
import { publisherVersioningSM } from 'src/state/machines/publisherVersioning/publisherVersioning.machine';
import {
  PublisherVersioningService,
  Versioning_Tab,
} from 'src/state/machines/publisherVersioning/publisherVersioning.types';
import * as VersioningModals from 'src/components/DNA/Modal/DNAVersioningModals';
import * as publisherVersioningSelector from 'src/state/machines/publisherVersioning/publisherVersioning.selectors';
import { DocumentInfoFormRef } from 'src/screens/Publishers/Versioning/DocumentInfo';
import { DocumentPublishFormRef } from 'src/screens/Publishers/Versioning/DocumentPublish';
import util from './util';
import logger from 'src/utils/logger';

export interface PublisherVersioningStateType {
  service: PublisherVersioningService,
  documentORM: DocumentORM,
  currentDocumentVersionORM: DocumentVersionORM,
  latestPublishedDocumentVersionORM?: DocumentVersionORM,
  latestDocumentVersionContentPageData?: ContentPageData[],
  setLatestDocumentVersionContentPageData: React.Dispatch<React.SetStateAction<ContentPageData[]>>
  latestPublishedVersionContentPageData?: ContentPageData[],
  documentInfoRef: React.RefObject<DocumentInfoFormRef>,
  documentPublishRef: React.RefObject<DocumentPublishFormRef>,
  isCriticalError: boolean;
  exceedsMaxOfflineSize: boolean;
  isWebDoc: boolean;
  isVideo: boolean;
  isHTMLDoc: boolean;
  isNonDocumentFile: boolean;
  isPasswordProtected: boolean;
  mappedValues: Record<string, MappingType>;

  // Functions
  toggleSlider: (onInvisCb?: () => void) => void,
  navigateToPreviousTab: () => void,
  navigateToNextTab: () => void,
  setSelectedIndex: DebouncedFuncLeading<(e: any) => void>,
  onVersionHistorySelect: (docVerId: string) => void,
  handleCreateFromExistingVersion: () => void,
  handleUploadNewVersion: (files: FileList | null) => void,
  handleExitButton: () => void,
  handleSaveDraft: () => void,
  handleDeleteDraft: () => void,
  handlePublish: () => void,
  handleSchedulePublish: () => void,
  handleCancelSchedulePublish: () => void,
  onSelectAssociatedFile: (parentDoc: DocumentVersionORM, linkedDoc: DocumentORM) => void,
  handleUploadAssociatedFileStatusChange: (status: UploadStatus) => void,
  handleFinishedUploadAssociatedFile: (attachedFile: AttachedFile) => void,
  handleUpdateAssociatedFile: (associatedFile: AssociatedFile) => void,
  handleDeleteAssociatedFile: (entityORM: DocumentVersionORM, item: DocumentORM | AttachedFile) => void,
}

export const PublisherVersioningStateContext = createContext<PublisherVersioningStateType | null>(null!);
PublisherVersioningStateContext.displayName = 'PublisherVersioningContext';

interface MappingType {
  mapping: string | null
  recommendations: Recommendations[]
}

const PublisherVersioningStateProvider: React.FC<PropsWithChildren<{
  documentORM: DocumentORM,
  toggleSlider: (onInvisCb?: () => void) => void,
  initialDocumentId?: string,
}>> = (props) => {
  const { toggleSlider, documentORM, children } = props;
  const { latestDocumentVersionORM } = documentORM.relations.version;
  const dispatch = useDispatch()
  const documentInfoRef = useRef<DocumentInfoFormRef>(null)
  const documentPublishRef = useRef<DocumentPublishFormRef>(null)
  const enableMatchSlides = useFeatureFlags('BEAC_4251_slide_version_tracking');
  const isFirstVersion = documentORM.relations.documentVersions.length === 1;

  /** STATE MACHINE */
  const machineInstance = useLazyRef(() => publisherVersioningSM.withContext({
    enableMatchSlides,
    availabletabs: util.getAvailabletabs(enableMatchSlides && !isFirstVersion),
    cancelUpload: false,
    documentVersionId: latestDocumentVersionORM.model.id,
    documentInfoIsDirty: false,
    documentSettingsIsDirty: false,
    documentSlidesDataIsDirty: false,
    documentPublishIsDirty: false,
    errors: {},
    getDocumentORM: util.getDocumentORMFactory(documentORM.model.id),
    hasOptimizedFinishedThisSession: false,
    selectedTabIndex: 0,
    versionActor: undefined,
    versionForm: util.omitInternalFields(latestDocumentVersionORM.model),
    slideSettingsActor: undefined,
  }));

  const service = useInterpret(
    machineInstance.current!,
    // { devTools: true },
  );

  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      publisherVersioningSelector.availabletabs,
      publisherVersioningSelector.isInDraftingState,
      publisherVersioningSelector.isNavBlocked,
      publisherVersioningSelector.documentSlidesDataIsDirty,
      publisherVersioningSelector.isDraftDirty,
      publisherVersioningSelector.documentVersionId,
    ),
  )

  // [NOTE] - if currentDocumentVersionORM is undefined, we will throw an error at VersioningPanel
  const currentDocumentVersionORM = useMemo(() => {
    const targetVersionORM = documentORM
      .relations
      .documentVersions
      .find(docVerORM => docVerORM.model.id === cond.documentVersionId)

    // [TODO-2126] - (BUG) There's a good chance that a delete will affect other publishers (concurrently)
    //             - The other windows record will auotmagically be removed from Redux causing another window
    //               to crash
    //             - Think it's okay to let it crash as is while we figure out a better fix

    return (
      targetVersionORM ??
      documentORM.relations.version.latestPublishedDocumentVersionORM
    )
  }, [documentORM, cond.documentVersionId])

  const isCriticalError = currentDocumentVersionORM?.model.conversionStatus === ConversionStatus.ERROR;
  const latestPublishedDocumentVersionORM = documentORM.relations.version.latestPublishedDocumentVersionORM;
  const presentable = getPresentable(documentORM
    .relations
    .documentVersions
    .find(docVerORM => docVerORM.model.id === cond.documentVersionId) ?? latestPublishedDocumentVersionORM);
  const { contentPageData } = useContentPageData(
    presentable,
    latestDocumentVersionORM?.model.status !== 'PUBLISHED',
    true,
  );
  const [latestDocumentVersionContentPageData, setLatestDocumentVersionContentPageData] =
    useState<ContentPageData[]>(contentPageData)

  const mappedValues = useMemo(() => {
    return latestDocumentVersionContentPageData?.reduce<Record<string, MappingType>>((acc, contentPageData) => {
      acc[contentPageData.pageId] = {
        mapping: contentPageData.mapping,
        recommendations: contentPageData.recommendations,
      }
      return { ...acc }
    }, {})
  }, [latestDocumentVersionContentPageData])

  useEffect(() => {
    // We only want to reassign latestDocumentVersionContentPageData when:
    // * the length is different (Initially it will be an emtpy array)
    // * the pageId changed (meaning the current version number had changed)
    const differentLength = contentPageData.length !== latestDocumentVersionContentPageData.length;
    const differentVersion = contentPageData?.[0]?.pageId !== latestDocumentVersionContentPageData?.[0]?.pageId;
    if (differentLength || differentVersion) {
      setLatestDocumentVersionContentPageData(contentPageData)
    }
  }, [contentPageData, latestDocumentVersionContentPageData])

  const latestPublishedVersionPresentable = getPresentable(latestPublishedDocumentVersionORM);
  const { contentPageData: latestPublishedVersionContentPageData } = useContentPageData(
    latestPublishedVersionPresentable,
  );
  const exceedsMaxOfflineSize = !!currentDocumentVersionORM?.model.convertedArchiveSize &&
    currentDocumentVersionORM?.model.convertedArchiveSize > MAX_OFFLINE_FILE_SIZE_BYTES
  const isWebDoc = currentDocumentVersionORM?.model.type === FileType.WEB
  const isVideo = currentDocumentVersionORM?.model.type === FileType.MP4
  const isHTMLDoc = currentDocumentVersionORM?.model.type === FileType.HTML
  const isNonDocumentFile = isWebDoc || isVideo || isHTMLDoc
  const isPasswordProtected = currentDocumentVersionORM?.model.conversionWarningCode ===
    ConversionWarningCode.PASSWORD_PROTECTED

  /** STATE MACHINE FUNCTIONS START */

  const navigateToPreviousTab = useCallback(() => {
    service.send('NAV_TAB_PREV')
  }, [service])

  const navigateToNextTab = useCallback(() => {
    service.send({
      type: 'NAV_TAB_NEXT',
      payload: { versionForm: documentInfoRef.current?.getDocumentInfoFormValues() },
    })
  }, [service])

  const setSelectedIndex = useCallback(throttle(e => {
    if (cond.isNavBlocked) return;
    const prefix = 'NAV_TO_';
    const targetTabEvent = cond.availabletabs[e]
      ? prefix + cond.availabletabs[e] as Versioning_Tab
      : undefined;
    const targetTab = targetTabEvent || 'NAV_TO_DOCUMENT_PUBLISH_TAB'

    const payload = targetTab === 'NAV_TO_DOCUMENT_PUBLISH_TAB'
      ? { versionForm: documentInfoRef.current?.getDocumentInfoFormValues() }
      : undefined
    service.send({ type: targetTab, payload })
  }, 750), [service, cond.isNavBlocked, cond.availabletabs]);

  const onVersionHistorySelect = useCallback((docVerId: string) => {
    service.send({
      type: 'SWITCH_DOCUMENT_VERSION',
      payload: { documentVersionId: docVerId },
    })
  }, [service])

  const handleCreateFromExistingVersion = useCallback(() => {
    service.send({ type: 'CREATE_FROM_EXISTING' })
  }, [service])

  const handleUploadNewVersion = useCallback((files: FileList | null) => {
    if (files) {
      const [file] = Array.from(files)

      if (!file) return;
      service.send({ type: 'CREATE_FROM_UPLOAD', payload: { file } })
    }
  }, [service])

  const handleExitButton = useCallback(() => {
    if (!cond.isDraftDirty) {
      toggleSlider()
      return;
    }

    dispatch(DNAModalActions.setModal({
      isVisible: true,
      allowBackdropCancel: true,
      component: () => <VersioningModals.DiscardChangesModal onConfirm={toggleSlider}/>,
    }))
  }, [cond.isDraftDirty, dispatch])

  // [NOTE] - Drafts can be saved as partial DocumentVersions without form validation
  const handleSaveDraft = useCallback(() => {
    const documentInfoFormData = documentInfoRef.current?.submitDraftValues() ?? null;

    if (!documentInfoFormData) return;

    const documentPublishFormData = documentPublishRef.current?.handleSaveDraftForDocumentPublish() ?? {};

    service.send({
      type: 'SAVE_DRAFT',
      payload: {
        versionForm: {
          ...documentInfoFormData,
          ...documentPublishFormData,
        },
        contentPageData: cond.documentSlidesDataIsDirty ? latestDocumentVersionContentPageData : undefined,
      },
    })
  }, [service, cond.documentSlidesDataIsDirty, latestDocumentVersionContentPageData])

  const handleDeleteDraft = useCallback(() => {
    const confirmDelete = () => {
      if (!currentDocumentVersionORM) return;
      const isFirstVersion = !currentDocumentVersionORM
        .relations
        .documentORM
        .relations
        .version
        .latestPublishedDocumentVersionORM

      // [NOTE] - In order to ensure the slick slider animation, we delete out-of-band for the machine
      //          While we could delete through the machine, the panel would go blank as the slider closes
      //          -or- the animation may not be smooth due to panel re-rendering performance
      isFirstVersion
        ? toggleSlider(() => dispatch(multiSliceActions.deleteDocumentDraft(currentDocumentVersionORM)))
        : service.send({ type: 'DELETE_DRAFT' })
    }

    dispatch(DNAModalActions.setModal({
      isVisible: true,
      allowBackdropCancel: true,
      component: () => <VersioningModals.DeleteDraftModal onConfirm={confirmDelete}/>,
    }))
  }, [service, currentDocumentVersionORM, dispatch])

  const validateFormData = useCallback(() => {
    const documentInfoFormData = documentInfoRef.current?.submitPublishValues() ?? null;
    const documentPublishFormData = documentPublishRef.current?.handlePublishDocumentInfo() ?? null;

    if (!documentInfoFormData || !documentPublishFormData) {
      if (!documentInfoFormData) service.send({ type: 'NAV_TO_DOCUMENT_INFO_TAB' })
      else if (!documentPublishFormData) service.send({ type: 'NAV_TO_DOCUMENT_PUBLISH_TAB' })
      return;
    }
    return {
      ...documentInfoFormData,
      ...documentPublishFormData,
    }
  }, [service])

  const handlePublish = useCallback(() => {
    const asyncWrapper = async () => {
      const formData = await validateFormData()
      if (!formData) return;

      const documentORM = currentDocumentVersionORM?.relations.documentORM;
      const sendPublishEvent = () => {
        service.send({
          type: 'PUBLISH_VERSION',
          payload: {
            versionForm: { ...formData },
            contentPageData : cond.documentSlidesDataIsDirty ? latestDocumentVersionContentPageData : undefined,
          },
        })
      }

      const isFirstVersion = documentORM?.relations.documentVersions.length === 1;
      const componentType = isFirstVersion
        ? () => <VersioningModals.PublishDocumentModal onConfirm={sendPublishEvent}/>
        : () => <VersioningModals.PublishVersionModal onConfirm={sendPublishEvent}/>

      dispatch(DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: true,
        component: componentType,
      }))
    }

    asyncWrapper()
  }, [
    service,
    dispatch,
    validateFormData,
    cond.documentSlidesDataIsDirty,
    currentDocumentVersionORM,
    latestDocumentVersionContentPageData,
  ])

  const handleSchedulePublish = useCallback(() => {
    const asyncWrapper = async () => {
      const formData = await validateFormData()
      if (!formData) return;

      const sendScheduleEvent = (scheduledPublish: Date) => {
        service.send({
          type: 'SCHEDULE_PUBLISH_VERSION',
          payload: {
            versionForm: {
              ...formData,
              scheduledPublish: scheduledPublish.toISOString(),
            },
            contentPageData : cond.documentSlidesDataIsDirty ? latestDocumentVersionContentPageData : undefined,
          },
        })
      }

      dispatch(DNAModalActions.setModal({
        isVisible: true,
        allowBackdropCancel: true,
        component: (props) =>
          (<DNASchedulePublishDocument
            { ...props }
            send={sendScheduleEvent}
          />),
      }))
    }
    asyncWrapper()
  }, [
    service,
    dispatch,
    validateFormData,
    cond.documentSlidesDataIsDirty,
    currentDocumentVersionORM,
    latestDocumentVersionContentPageData,
  ])

  const handleCancelSchedulePublish = useCallback(() => {
    const sendCancelScheduleEvent = () => {
      service.send({ type: 'CANCEL_SCHEDULE_VERSION' })
    }

    dispatch(DNAModalActions.setModal({
      isVisible: true,
      allowBackdropCancel: true,
      component: () => <VersioningModals.CancelSchedulePublishModal onConfirm={sendCancelScheduleEvent}/>,
    }))
  }, [service, dispatch])

  const onSelectAssociatedFile = useCallback((_, linkedDocument: DocumentORM) => {
    analytics?.track('SEARCH_AF', {
      action: 'INPUT',
      category: 'SEARCH',
    });
    service.send({
      type: 'ASSOCIATED_DOCUMENT_LINK',
      payload: { documentId: linkedDocument.model.id },
    })
  }, [service])

  const handleUploadAssociatedFileStatusChange = useCallback((status: UploadStatus) => {
    service.send({
      type: 'ATTACHED_FILE_UPLOAD_STATUS_CHANGE',
      payload: { status },
    })
  }, [service])

  const handleFinishedUploadAssociatedFile = useCallback((attachedFile: AttachedFile) => {
    service.send({
      type: 'ATTACHED_FILE_UPLOADED',
      payload: { attachedFile: attachedFile },
    })
  }, [service])

  const handleUpdateAssociatedFile = useCallback((associatedFile: AssociatedFile) => {
    service.send({
      type: 'ASSOCIATED_FILE_UPDATED',
      payload: { associatedFile },
    })
  }, [service])

  const handleDeleteAssociatedFile = useCallback((_, item: DocumentORM | AttachedFile) => {
    service.send({
      type: 'ASSOCIATED_FILE_DELETE',
      payload: {
        attachmentId: item.type === ORMTypes.DOCUMENT
          ? item.model.id
          : item.id,
      },
    })
  }, [service])

  /** STATE MACHINE FUNCTIONS END */

  if (!currentDocumentVersionORM) {
    const errorText = `Could not open Document for versioning: ${documentORM.model.id}`;
    logger.versioning.error(errorText);
    return (
      <DNABox
        fill
        appearance="col"
        alignX="center"
        alignY="center"
        style={{ backgroundColor: 'white' }}
      >
        <DNAError
          promptLogout={false}
          message="Could not open Document for versioning!"
        >
          <DNAText>{documentORM.model.id}</DNAText>
          <DNAButton
            appearance="ghost"
            onPress={() => toggleSlider()}
          >
            Go back
          </DNAButton>
        </DNAError>
      </DNABox>
    )
  }

  const contextValue: PublisherVersioningStateType = {
    service,
    documentORM,
    currentDocumentVersionORM,
    latestPublishedDocumentVersionORM,
    latestDocumentVersionContentPageData,
    setLatestDocumentVersionContentPageData,
    latestPublishedVersionContentPageData,
    documentInfoRef,
    documentPublishRef,
    isCriticalError,
    exceedsMaxOfflineSize,
    isWebDoc,
    isVideo,
    isHTMLDoc,
    isNonDocumentFile,
    isPasswordProtected,
    mappedValues,

    // Functions
    toggleSlider,
    navigateToPreviousTab,
    navigateToNextTab,
    setSelectedIndex,
    onVersionHistorySelect,
    handleCreateFromExistingVersion,
    handleUploadNewVersion,
    handleExitButton,
    handleSaveDraft,
    handleDeleteDraft,
    handlePublish,
    handleSchedulePublish,
    handleCancelSchedulePublish,
    onSelectAssociatedFile,
    handleUploadAssociatedFileStatusChange,
    handleFinishedUploadAssociatedFile,
    handleUpdateAssociatedFile,
    handleDeleteAssociatedFile,
  };

  return (
    <PublisherVersioningStateContext.Provider value={contextValue}>
      {children}
    </PublisherVersioningStateContext.Provider>
  );
};

PublisherVersioningStateProvider.displayName = 'PublisherVersioningStateProvider';

export const usePublisherVersioningState = () => {
  const context = useContext(PublisherVersioningStateContext);
  if (!context) {
    throw new Error('usePublisherVersioningState must be used within the PublisherVersioningStateProvider');
  }
  return context;
};

export default PublisherVersioningStateProvider;
