import React, { useCallback, useMemo } from 'react';
import { ScrollView } from 'react-native';
import { DNABox } from '@alucio/lux-ui';
import useMachineSelector, { composite } from 'src/hooks/useSelector';
import * as publisherVersioningSelector from 'src/state/machines/publisherVersioning/publisherVersioning.selectors';
import { usePublisherVersioningState }
  from 'src/state/machines/publisherVersioning/PublisherVersioningProvider';
import MatchSlidesStateProvider, {
  useMatchSlidesState,
} from 'src/state/machines/publisherVersioning/MatchSlides/MatchSlidesProvider';
// Dnd
import DNASingleItemDnd, { TargetItem } from 'src/components/DnD/DNASingleItemDnd';
import SlidesGroupPool from './SlidesGroupPool';
import SlideGroupTargets from './SlideGroupTargets';
import SlidesGroupOverlay from './SlidesGroupOverlay';
import MatchSlidesReadonly from './MatchSlidesReadonly';

const MatchSlidesEditMode: React.FC = () => {
  const {
    service,
    mappedValues,
    setLatestDocumentVersionContentPageData,
  } = usePublisherVersioningState();
  const {
    currentDocumentVersionORM,
    latestPublishedDocumentVersionORM,
  } = useMatchSlidesState();

  /* Array of pageId from the lastest published docVer */
  const poolItems = useMemo(() => latestPublishedDocumentVersionORM.relations.pages.map(page => page.model.pageId),
    [latestPublishedDocumentVersionORM]);

  /* Object with keys as current draft's pageIds and value as an array contains the selected matching pageId */
  const targetItems = useMemo(() => {
    return currentDocumentVersionORM?.relations.pages.reduce<Record<string, string[]>>((acc, page) => {
      const mappedPage = mappedValues[page.model.pageId]
      if (!mappedValues || !mappedPage) return { ...acc, [page.model.pageId]: [] }
      if (!mappedPage.mapping) {
        return {
          ...acc,
          [page.model.pageId]:
            mappedPage.recommendations.length > 0
              ? [mappedPage?.recommendations[0]?.pageId]
              : [],
        }
      } else if (mappedPage.mapping === 'USER_UNMATCHED') {
        return {
          ...acc,
          [page.model.pageId]: [],
        }
      } else {
        return {
          ...acc,
          [page.model.pageId] : [mappedPage.mapping],
        }
      }
    }, {})
  }, [currentDocumentVersionORM, mappedValues]);

  const onDragEndChanged = useCallback((targetItems: { [key: string]: TargetItem[] }) => {
    const docVerId = currentDocumentVersionORM.model.id
    service.send({ type: 'SET_IS_DIRTY', payload: { type: 'slidesData', isDirty: true } })
    setLatestDocumentVersionContentPageData(preState => {
      return preState.map(pageData => {
        const pageId = `${docVerId}_${pageData.presentationPageNumber}`;
        const itemId: string | undefined = targetItems[pageId]?.[0]?.itemId;
        if (itemId) return { ...pageData, mapping: itemId }
        else return { ...pageData }
      })
    })
  }, [])

  return (
    <DNASingleItemDnd
      poolItems={poolItems}
      targetItems={targetItems}
      collisionDetection={'pointerWithin'}
      onDragEndChanged={onDragEndChanged}
    >
      <DNABox as={ScrollView} fill >
        {/* left side bar */}
        <SlidesGroupPool />
        {/* right side */}
        <SlideGroupTargets />
      </DNABox>
      <SlidesGroupOverlay />
    </DNASingleItemDnd>
  )
}

const MatchSlidesReadOnlyMode: React.FC = () => {
  return (
    <DNABox fill>
      <MatchSlidesReadonly />
    </DNABox>
  )
}

const MatchSlidesWrapper: React.FC = () => {
  const {
    service,
    latestPublishedDocumentVersionORM,
  } = usePublisherVersioningState();
  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      publisherVersioningSelector.isInDraftingState,
    ),
  )

  if (!latestPublishedDocumentVersionORM) return null;
  else if (cond.isInDraftingState) {
    return (
      <MatchSlidesStateProvider>
        <MatchSlidesEditMode />
      </MatchSlidesStateProvider>
    )
  } else {
    return (
      <MatchSlidesStateProvider>
        <MatchSlidesReadOnlyMode />
      </MatchSlidesStateProvider>
    )
  }
}

export default MatchSlidesWrapper;
