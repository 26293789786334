import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { StyleProp, StyleSheet, useWindowDimensions, ViewStyle } from 'react-native';
import {
  DNABox,
  DNAButton,
  DNAChip,
  DNADivider,
  DNAIcon,
  DNAIconStyleProps,
  DNAText,
  Iffy,
  util,
} from '@alucio/lux-ui';
import { useMediaQuery } from 'react-responsive';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import DNAThumbnail from 'src/components/DNA/Thumbnail/DNAThumbnail';
import { getSlideTitle, getThumbURL } from 'src/utils/thumbnailHelpers';
import { usePublisherVersioningState }
  from 'src/state/machines/publisherVersioning/PublisherVersioningProvider';
import { useMatchSlidesState } from 'src/state/machines/publisherVersioning/MatchSlides/MatchSlidesProvider';
// Dnd
import {
  DroppableContainer,
  POOL_CONTAINER_ID,
  SortableItem,
  TargetItem,
  useSingleItemDnd,
} from 'src/components/DnD/DNASingleItemDnd';
import SlideGroupTargetsStateProvider, {
  MATCH_SLIDE_STATUS,
  useSlideGroupTargetsState,
} from './SlideGroupTargetsProvider';
import { LuxSizeEnum } from '@alucio/lux-ui/src/typings';
import { DndMonitorListener, useDndMonitor } from '@dnd-kit/core';
import { useDispatch } from 'src/state/redux';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import MatchSlidesPreviewModal from './MatchSlidesPreviewModal';
import { DataProvider, LayoutProvider, RecyclerListView, RecyclerListViewProps } from 'recyclerlistview';
import { thumbnailSizeDimensions } from 'src/hooks/useThumbnailSize/useThumbnailSize';
import { Page } from '@alucio/aws-beacon-amplify/src/models';
import CollapsibleCard from 'src/components/CollapsibleCard/CollapsibleCard';

const S = StyleSheet.create({
  container: {
    padding: 32,
  },
  countIcon: {
    backgroundColor: colors['color-success-500'],
    borderRadius: 22,
    padding: 4,
    marginHorizontal: 4,
  },
  recyclerListView: {
    flex: 1,
    paddingVertical: 24,
  },
  groupContainerDragOver: {
    borderStyle: 'dashed',
    borderColor: colors['color-primary-400'],
    borderWidth: 2,
  },
  emptyMatchingContainer: {
    width: thumbnailSizeDimensions.lg.width,
    height: thumbnailSizeDimensions.lg.height,
    borderStyle: 'dashed',
    borderWidth: 3,
    borderColor: colors['color-gray-200'],
    borderRadius: 4,
    backgroundColor: colors['color-gray-10'],
  },
  hoverStateEmptyMatchingContainer: {
    borderColor: colors['color-primary-500'],
  },
  emptyMatchingContainerText: {
    color: colors['color-text-tertiary'],
  },
  recommendedConfirmationContainer: {
    backgroundColor: colors['color-warning-5'],
    borderColor: colors['color-warning-500'],
    borderWidth: 1,
    borderRadius: 4,
    paddingVertical: 8,
    paddingHorizontal: 16,
  },
  recommendedConfirmationAlert: {
    fontSize: 20,
    color: colors['color-warning-500'],
  },
  iconContainer: {
    borderRadius: 32,
    width: 32,
    height: 32,
  },
  iconContainerSmallerScreen : {
    borderRadius: 24,
    width: 24,
    height: 24,
  },
  columnHeaderContainer: {
    backgroundColor: colors['color-gray-80'],
    borderRadius: 8,
    width: 342,
  },
  columnHeaderContainerSmallerScreen: {
    backgroundColor: colors['color-gray-80'],
    borderRadius: 8,
    width: 242,
  },
  columnHeaderText: {
    color: colors['color-text-tertiary'],
    paddingVertical: 8,
  },
  headerContainer: {
    zIndex: 3,
    paddingVertical: 12,
    marginTop: 8,
    borderRadius: 8,
    backgroundColor: colors['color-text-basic'],
    borderColor: colors['color-gray-100'],
  },
  headerText: {
    paddingHorizontal: 41,
    paddingVertical: 32,
    textAlign: 'center',
  },
  helpStyle: {
    borderRadius: 4,
    borderColor: colors['color-gray-100'],
    backgroundColor: colors['color-text-basic'],
  },
  notEqualSign: {
    backgroundColor: colors['color-text-tertiary'],
    borderRadius: 22,
    color: colors['color-text-basic'],
  },
  notEqualSignOpaque: {
    backgroundColor: colors['color-text-tertiary'],
    borderRadius: 22,
    color: colors['color-gray-100'],
  },
  helpContainer: {
    width: 760,
  },
  helpContainerSmallerScreen: {
    width: 586,
  },
})

interface PreviousVersionContainerProps {
  pageId: string,
  index: number,
}

interface RecommendationConfirmationProps {
  pageId: string
}

interface SlideMatchingIconProps {
  pageId: string
}

interface NewVersionContainerProps {
  pageId: string,
  newPageNum: number
}

interface SelectedMatchingSlideProps {
  matchingSlide: TargetItem[],
  containerId: string,
}

interface SlideGroupTargetsRowType {
  page: Page,
  index: number
}

const PreviousVersionContainer: React.FC<PreviousVersionContainerProps> = ({ pageId, index }) => {
  const { onScrollToIndexInSlideGroupPool } = useMatchSlidesState();
  const { overContainer } = useSlideGroupTargetsState();
  const { activeId, groupedTargetItems } = useSingleItemDnd();
  const matchingSlide = groupedTargetItems[pageId]
  const isLargeScreen = useMediaQuery({
    query: '(min-width: 1300px)',
  })

  // Disable dropable container when it contains item and NOT during dragging.
  // So user cannot drag item within this container but only able to replace it during dnd.
  const disableDropable = !!matchingSlide?.length && !activeId

  return (
    <DroppableContainer
      id={pageId}
      items={matchingSlide}
      strategy={'verticalListSortingStrategy'}
      disabled={disableDropable}
    >
      <Iffy is={matchingSlide.length}>
        <SelectedMatchingSlide matchingSlide={matchingSlide} containerId={pageId}/>
      </Iffy>
      <Iffy is={!matchingSlide.length}>
        <DNABox
          fill
          appearance="col"
          alignX="center"
          alignY="center"
          spacing="md"
          style={util.mergeStyles(
            undefined,
            S.emptyMatchingContainer,
            [S.hoverStateEmptyMatchingContainer, overContainer === pageId],
            { width: isLargeScreen ? thumbnailSizeDimensions.lg.width : thumbnailSizeDimensions.md.width },
            { height: isLargeScreen ? thumbnailSizeDimensions.lg.height : thumbnailSizeDimensions.md.height },
          )}
        >
          <DNABox fill appearance="col" alignX="center" spacing="sm">
            <DNAText b1 bold style={S.emptyMatchingContainerText}>
              No matching slide
            </DNAText>
            <DNAText b1 style={S.emptyMatchingContainerText}>
              Drag slide here if there's a match
            </DNAText>
          </DNABox>
          <DNAButton
            onPress={() => onScrollToIndexInSlideGroupPool(index)}
            appearance="outline"
          >
            Find slide
          </DNAButton>
        </DNABox>
      </Iffy>
    </DroppableContainer>
  )
}

const NewVersionContainer: React.FC<NewVersionContainerProps> = ({ pageId, newPageNum }) => {
  const {
    latestDocumentVersionContentPageData,
    latestPublishedVersionContentPageData,
  } = usePublisherVersioningState();
  const {
    currentDocumentVersionORM,
    latestPublishedDocumentVersionORM,
  } = useMatchSlidesState();
  const {
    groupedTargetItems,
  } = useSingleItemDnd()
  const dispatch = useDispatch()
  const isLargeScreen = useMediaQuery({
    query: '(min-width: 1300px)',
  })

  const previousPageId = groupedTargetItems[pageId]?.[0]?.itemId || ''
  const previousPageNum = previousPageId?.split('_')?.pop()

  const togglePreviewModal = ({ previousPageNum, newPageNum }) => {
    dispatch(DNAModalActions.setModal({
      isVisible: true,
      allowBackdropCancel: true,
      component: (props) => (
        <MatchSlidesPreviewModal
          newDocumentVersionORM={currentDocumentVersionORM}
          previousDocumentVersionORM={latestPublishedDocumentVersionORM}
          newPageNum={newPageNum}
          previousPageNum={previousPageNum}
          latestDocumentVersionContentPageData={latestDocumentVersionContentPageData}
          latestPublishedVersionContentPageData={latestPublishedVersionContentPageData}
          {...props}
        />
      ),
    }))
  }

  const thumbURL = getThumbURL(currentDocumentVersionORM, newPageNum)
  const thumbnailTitle = getSlideTitle(
    currentDocumentVersionORM,
    newPageNum,
    latestDocumentVersionContentPageData,
  )
  return (
    <DNABox
      fill
      childFill={1}
      appearance="col"
      alignX="center"
      alignY="start"
      key={`last-version-thumbnail-${pageId}`}
      testID={`last-version-thumbnail-${pageId}`}
      style={{ paddingBottom: 78 }}
    >
      <DNAThumbnail
        variant={DNAThumbnail.Variants.MATCH_SLIDE}
        key={thumbURL}
        s3URL={thumbURL}
        useLoadingIndicator
        thumbnailTitle={thumbnailTitle}
        style={{ margin: 0 }}
        size={isLargeScreen ? 'lg' : 'md'}
        pageNumber={newPageNum}
        onCheck={() => togglePreviewModal({
          newPageNum,
          previousPageNum,
        })}
      />
    </DNABox>
  )
}

const SelectedMatchingSlide: React.FC<SelectedMatchingSlideProps> = ({ matchingSlide, containerId }) => {
  const {
    latestDocumentVersionContentPageData,
    latestPublishedVersionContentPageData,
    mappedValues,
  } = usePublisherVersioningState();
  const {
    currentDocumentVersionORM,
    latestPublishedDocumentVersionORM,
  } = useMatchSlidesState();
  const { groupedTargetItems, setGroupedTargetItems } = useSingleItemDnd()
  const { onUnmatch } = useMatchSlidesState()
  const dispatch = useDispatch()
  const isLargeScreen = useMediaQuery({
    query: '(min-width: 1300px)',
  })

  const togglePreviewModal = ({ previousPageNum, newPageNum }) => {
    dispatch(DNAModalActions.setModal({
      isVisible: true,
      allowBackdropCancel: true,
      component: (props) => (
        <MatchSlidesPreviewModal
          newDocumentVersionORM={currentDocumentVersionORM}
          previousDocumentVersionORM={latestPublishedDocumentVersionORM}
          newPageNum={newPageNum}
          previousPageNum={previousPageNum}
          latestDocumentVersionContentPageData={latestDocumentVersionContentPageData}
          latestPublishedVersionContentPageData={latestPublishedVersionContentPageData}
          {...props}
        />
      ),
    }))
  }

  const onRevert = useCallback((pageId: string, firstRecommendedPageId: string) => {
    setGroupedTargetItems(p => {
      return {
        ...p,
        [pageId]: p[containerId] ? [{
          id: p[pageId][0].id,
          itemId: firstRecommendedPageId,
        }] : [],
      }
    })
  }, [setGroupedTargetItems])

  return (
    <>
      {
        matchingSlide.map(({ id, itemId }, idx) => {
          // since itemid or id could be duplicated if we add the same slide multiple times
          // we need to add a unique key to the sortable item
          // so that it can be sorted and remove correctly if whe have the same slides multiple times
          // the keys will be itemId, itemId-2, etc
          const slideLength = matchingSlide.slice(0, idx + 1).filter(i => i.itemId === itemId).length
          const key = slideLength > 1 ? `${itemId}-${slideLength}` : `${itemId}`
          const previousPageNum = Number(itemId.split('_').pop() ?? 0);
          const newPageNum = Number(groupedTargetItems[containerId][0]?.itemId.split('_').pop() ?? 0);
          const firstRecommendedPageId = mappedValues[containerId]?.recommendations[0]?.pageId
          const canRevert = groupedTargetItems[containerId] &&
            groupedTargetItems[containerId][0]?.itemId !== firstRecommendedPageId

          const thumbnailTitle = getSlideTitle(
            latestPublishedDocumentVersionORM,
            newPageNum,
            latestPublishedVersionContentPageData,
          )

          return (
            <SortableItem
              key={key}
              id={id}
              itemId={itemId}
              containerId={containerId}
            >
              <DNABox appearance="col">
                <DNAThumbnail
                  s3URL={getThumbURL(latestPublishedDocumentVersionORM, previousPageNum)}
                  useLoadingIndicator
                  size={isLargeScreen ? 'lg' : 'md'}
                  mode={DNAThumbnail.Modes.REMOVABLE_AND_PREVIEW}
                  thumbnailTitle={thumbnailTitle}
                  variant={DNAThumbnail.Variants.MATCH_SLIDE}
                  onClose={() => onUnmatch(containerId)}
                  pageNumber={previousPageNum}
                  onCheck={() => togglePreviewModal({
                    previousPageNum,
                    newPageNum,
                  })}
                  onRevert={canRevert ? () => onRevert(containerId, firstRecommendedPageId) : undefined}
                  style={{ margin: 0 }}
                />
              </DNABox>
            </SortableItem>
          )
        })
      }
    </>
  )
}

const Header: React.FC = () => {
  const { onUnmatch, slideGroupTargetRef, toggleSlidesGroupPoolVisible } = useMatchSlidesState();
  const { onScrollToStatus, slideCount } = useSlideGroupTargetsState()
  const [helpVisible, setHelpVisible] = useState<boolean>(false)
  const isLargeScreen = useMediaQuery({
    query: '(min-width: 1300px)',
  })

  return (
    <DNABox appearance="col" alignX="center">
      {/* Header action bar */}
      <DNABox
        appearance="row"
        alignX="center"
        alignY="center"
        spacing="sm"
        style={util.mergeStyles(
          undefined,
          S.headerContainer,
          { width: isLargeScreen ? 832 : 586 },
        )}
      >
        <DNAButton
          onPress={toggleSlidesGroupPoolVisible}
          appearance="outline"
          status="tertiary"
          size="sm"
          padding="sm"
        >
          Find slides
        </DNAButton>
        <DNADivider vertical />
        <DNAButton
          iconLeft="alert-circle"
          appearance="outline"
          status="warning"
          size="sm"
          padding="sm"
          disabled={slideCount.needsReview < 1}
          onPress={() => onScrollToStatus(MATCH_SLIDE_STATUS.NEEDS_REVIEW)}
        >
          {isLargeScreen ? 'Needs Review' : ''} ({slideCount.needsReview})
        </DNAButton>
        <DNAButton
          iconLeft="not-equal-variant"
          iconStyle={util.mergeStyles(
            undefined,
            [slideCount.noMatch < 1, S.notEqualSignOpaque],
            [slideCount.noMatch > 0, S.notEqualSign],
          )}
          appearance="outline"
          status="tertiary"
          size="sm"
          padding="sm"
          disabled={slideCount.noMatch < 1}
          onPress={() => onScrollToStatus(MATCH_SLIDE_STATUS.NO_MATCH)}
        >
          {isLargeScreen ? 'No-match' : ''} ({slideCount.noMatch})
        </DNAButton>
        <DNAText b1 status="success">
          <DNAIcon.Styled
            name="arrow-right"
            size={isLargeScreen ? LuxSizeEnum.md : LuxSizeEnum.sm}
            style={S.countIcon}
          />
          {`${slideCount.total - slideCount.needsReview - slideCount.noMatch} of ${slideCount.total} matches`}
        </DNAText>
        <DNADivider vertical style={{ marginHorizontal: 4 }} />
        <DNAButton
          onPress={() => onUnmatch('all')}
          appearance="outline"
          status="tertiary"
          size="sm"
          padding="sm"
          iconLeft={isLargeScreen ? undefined : 'not-equal-variant'}
        >
          {isLargeScreen ? 'Unmatch all' : ''}
        </DNAButton>
        <DNAButton
          appearance="outline"
          status="tertiary"
          size="lg"
          padding="xs"
          iconLeft="arrow-up"
          onPress={() => slideGroupTargetRef.current?.scrollToTop(true)}
        />
        <DNAButton
          appearance="outline"
          status="tertiary"
          size="lg"
          padding="xs"
          iconLeft="help-circle-outline"
          onPress={() => setHelpVisible(!helpVisible)}
        />
      </DNABox>
      <DNABox alignX="center">
        <DNAText
          bold
          style={util.mergeStyles(
            undefined,
            S.headerText,
            { width: isLargeScreen ? 832 : 586 },
          )}
        >
          We've auto-matched slides to the previous version. Please review to make sure they all match.
        </DNAText>
      </DNABox>
      <Iffy is={helpVisible}>
        <DNABox
          style={util.mergeStyles(
            undefined,
            isLargeScreen ? S.helpContainer : S.helpContainerSmallerScreen,
          )
            }
          spacing="sm"
          appearance="col"
          alignX="end"
        >
          <DNAButton
            appearance="outline"
            status="tertiary"
            size="lg"
            padding="xs"
            iconLeft="close"
            onPress={() => setHelpVisible(false)}
          />
          <DNABox spacing="sm" fill appearance="col">
            <CollapsibleCard
              headerTitle={<DNAText b1 bold>Why should I match the slides?</DNAText>}
              isCollapsed={true}
              cardStyle={S.helpStyle}
            >
              <DNABox>
                <DNAText b1 numberOfLines={3} style={{ padding: 32 }}>
                  {/* eslint-disable-next-line max-len */}
                  When you match slides between versions, information about the slide is carried from version to version. This will allow viewers to automatically update their custom decks with the slides you have chosen, saving them time and effort. In addition, slide trends can be tracked over time.
                </DNAText>
              </DNABox>
            </CollapsibleCard>
            <CollapsibleCard
              headerTitle={<DNAText b1 bold>How do I match the slides?</DNAText>}
              isCollapsed={true}
              cardStyle={S.helpStyle}
            >
              <DNABox>
                <DNAText b1 numberOfLines={5} style={{ padding: 32 }}>
                  {/* eslint-disable-next-line max-len */}
                  1. Click on the “Find slides” button in the top action bar to open the left slide list. {'\n'}
                  Or click on the dotted slides to open the left slide list. {'\n'}
                  2. From list, find the slide that corresponds with the slide in the NEW version {'\n'}
                  3. Drag and drop that slide into the dotted slide container {'\n'}
                </DNAText>
              </DNABox>
            </CollapsibleCard>
          </DNABox>
        </DNABox>
      </Iffy>
      <DNABox appearance="row" spacing="xl" alignX="center" style={{ padding: 8 }}>
        <DNABox
          style={util.mergeStyles(
            undefined,
            isLargeScreen ? S.columnHeaderContainer : S.columnHeaderContainerSmallerScreen,
          )
          }
          alignX="center"
          spacing="sm"
        >
          <DNAText style={S.columnHeaderText}>Version 1.0</DNAText>
          <DNABox style={{ paddingVertical: 8 }}>
            <DNAChip style={{ borderRadius: 2 }} appearance="tag">CURRENT</DNAChip>
          </DNABox>
        </DNABox>
        <DNABox
          style={util.mergeStyles(
            undefined,
            isLargeScreen ? S.columnHeaderContainer : S.columnHeaderContainerSmallerScreen,
          )
          }
          alignX="center"
          spacing="sm"
        >
          <DNAText style={S.columnHeaderText}>Version 2.0</DNAText>
          <DNABox style={{ paddingVertical: 8 }}>
            <DNAChip style={{ borderRadius: 2 }} appearance="tag" status="success">New</DNAChip>
          </DNABox>
        </DNABox>
      </DNABox>
    </DNABox>
  )
}

const RecommendedConfirmation: React.FC<RecommendationConfirmationProps> = ({ pageId }) => {
  const { groupedTargetItems } = useSingleItemDnd();
  const { onMatch, onUnmatch } = useMatchSlidesState();
  const { matchSlideIconStates } = useSlideGroupTargetsState();

  // TODO maybe move this to useSlideGroupTargetsState for less repatitve code. mappedItem is being calculated in that provider already
  const mappedItem = useMemo(() => groupedTargetItems[pageId]?.[0]?.itemId || null, [groupedTargetItems, pageId])

  const showConfirmationMessage = matchSlideIconStates[pageId] === MATCH_SLIDE_STATUS.NEEDS_REVIEW

  if (!showConfirmationMessage || !mappedItem) return null
  return (
    <DNABox
      alignX="start"
      alignY="center"
      spacing="sm"
      fill
      style={S.recommendedConfirmationContainer}
    >
      <DNAIcon
        name="alert-circle"
        status="warning"
        style={S.recommendedConfirmationAlert}
      />
      <DNAText status="warning">
        Do these slides match?
      </DNAText>
      <DNAButton
        status="warning"
        appearance="outline"
        onPress={() => onMatch(pageId, mappedItem)}
        size="xs"
      >
        Yes
      </DNAButton>
      <DNAButton
        status="warning"
        appearance="outline"
        onPress={() => onUnmatch(pageId)}
        size="xs"
      >
        No
      </DNAButton>
    </DNABox>
  )
}

const SlideMatchingIcon: React.FC<SlideMatchingIconProps> = ({ pageId }) => {
  const { matchSlideIconStates } = useSlideGroupTargetsState();
  const isLargeScreen = useMediaQuery({
    query: '(min-width: 1300px)',
  })

  type IconVariantsValues = {
    iconProps: DNAIconStyleProps,
    iconContainerProps: StyleProp<ViewStyle>,
  }
  const iconVariants: Record<MATCH_SLIDE_STATUS, IconVariantsValues> = {
    [MATCH_SLIDE_STATUS.MATCHED]: {
      iconProps: {
        name: 'check-bold',
        size: isLargeScreen ? LuxSizeEnum.md : LuxSizeEnum.sm,
      },
      iconContainerProps: {
        backgroundColor: colors['color-success-500'],
      },
    },
    [MATCH_SLIDE_STATUS.NO_MATCH]: {
      iconProps: {
        name: 'not-equal-variant',
        size: isLargeScreen ? LuxSizeEnum.md : LuxSizeEnum.sm,
      },
      iconContainerProps: {
        backgroundColor: colors['color-gray-400'],
      },
    },
    [MATCH_SLIDE_STATUS.NEEDS_REVIEW]: {
      iconProps: {
        name: 'exclamation',
        size: isLargeScreen ? LuxSizeEnum.md : LuxSizeEnum.sm,
      },
      iconContainerProps: {
        backgroundColor: colors['color-warning-500'],
      },
    },
  }

  if (!matchSlideIconStates[pageId]) return null
  const iconVariant = iconVariants[matchSlideIconStates[pageId]]

  return (
    <DNABox
      alignX="center"
      alignY="center"
      style={util.mergeStyles(
        undefined,
        { height: isLargeScreen ? thumbnailSizeDimensions.lg.height : thumbnailSizeDimensions.md.height },
      )}
    >
      <DNABox
        alignX="center"
        alignY="center"
        style={util.mergeStyles(
          undefined,
          iconVariant.iconContainerProps,
          isLargeScreen ? S.iconContainer : S.iconContainerSmallerScreen,
        )}
      >
        <DNAIcon.Styled
          {...iconVariant.iconProps}
        />
      </DNABox>
    </DNABox>
  )
}

// Renderless component that minimizes rerenders due to chatty over event
const GroupHoverTrigger = React.memo(() => {
  const { activeContainerOrigin } = useSingleItemDnd();
  const { setOverContainer } = useSlideGroupTargetsState()

  const listeners = useMemo<DndMonitorListener>(
    () => ({
      onDragOver(event) {
        const isFromPool = activeContainerOrigin.current === POOL_CONTAINER_ID
        const overContainer = event.over?.data.current?.containerId

        if (isFromPool && overContainer) {
          setOverContainer(overContainer)
        }
      },
      onDragEnd() {
        setOverContainer(null)
      },
    }),
    [setOverContainer],
  )

  useDndMonitor(listeners)

  return null
})

const SlideGroupTargetsRow: React.FC<SlideGroupTargetsRowType> = ({ page, index }) => {
  const { matchSlideIconStates } = useSlideGroupTargetsState();

  const needsReview = matchSlideIconStates[page.pageId] === MATCH_SLIDE_STATUS.NEEDS_REVIEW
  return (
    <DNABox testID="slide-group-container" fill appearance="col">
      <DNABox
        fill
        appearance="col"
        alignX="center"
        spacing="md"
      >
        <RecommendedConfirmation pageId={page.pageId} />
        <DNABox appearance="row" spacing="md" style={util.mergeStyles(undefined, [{ marginTop: 24 }, !needsReview])}>
          <PreviousVersionContainer pageId={page.pageId} index={index}/>
          <SlideMatchingIcon pageId={page.pageId} />
          <NewVersionContainer
            pageId={page.pageId}
            newPageNum={page.number}
          />
        </DNABox>
      </DNABox>
      <GroupHoverTrigger />
    </DNABox>
  )
}

const SlideGroupTargets: React.FC = () => {
  const {
    currentDocumentVersionORM,
    latestPublishedDocumentVersionORM,
  } = useMatchSlidesState();
  const { slideGroupTargetRef } = useMatchSlidesState()
  const dimensions = useWindowDimensions()
  const isLargeScreen = useMediaQuery({
    query: '(min-width: 1300px)',
  })

  const dataProviderInit = useMemo(() => {
    return new DataProvider((r1: string, r2: string) => {
      return r1 !== r2;
    });
  }, []);

  const dataProvider = useMemo(() => {
    return dataProviderInit.cloneWithRows(currentDocumentVersionORM.relations.pages)
  }, [currentDocumentVersionORM]);

  const layoutProvider = useMemo(() => {
    const _layoutProvider = new LayoutProvider(() => 0,
      (type, dim, _idx) => {
        const containerWidth = dimensions.width
        const containerHeight = 92
        const thumbnailSizeDimension = isLargeScreen ? thumbnailSizeDimensions.lg : thumbnailSizeDimensions.md;
        dim.width = containerWidth;
        dim.height = containerHeight + thumbnailSizeDimension.height;
      })
    _layoutProvider.shouldRefreshWithAnchoring = false;
    return _layoutProvider;
  }, []);

  const rowRenderer = useCallback<RecyclerListViewProps['rowRenderer']>(
    (type, data, idx) => {
      const { model } = data;
      const page = model as Page
      return (
        <SlideGroupTargetsRow index={idx} page={page} key={page.pageId} />
      )
    },
    [layoutProvider, dataProvider, latestPublishedDocumentVersionORM])

  return (
    <SlideGroupTargetsStateProvider>
      <DNABox fill appearance="col">
        {/* HEADER SECTION */}
        <Header />
        <RecyclerListView
          key={latestPublishedDocumentVersionORM.model.id}
          layoutProvider={layoutProvider}
          dataProvider={dataProvider}
          rowRenderer={rowRenderer}
          style={S.recyclerListView}
          canChangeSize
          ref={slideGroupTargetRef}
        />
      </DNABox>
    </SlideGroupTargetsStateProvider>
  )
}

export default SlideGroupTargets;
