import React, {
  createContext,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { usePublisherVersioningState } from 'src/state/machines/publisherVersioning/PublisherVersioningProvider';
import { useSingleItemDnd } from 'src/components/DnD/DNASingleItemDnd';
import { useMatchSlidesState } from 'src/state/machines/publisherVersioning/MatchSlides/MatchSlidesProvider';

export enum MATCH_SLIDE_STATUS {
MATCHED = 'MATCHED',
NO_MATCH = 'NO_MATCH',
NEEDS_REVIEW = 'NEEDS_REVIEW',
}

const SCORE_THRESHOLD = 0.8

export interface SlideGroupTargetsContextType {
  matchSlideIconStates: Record<string, MATCH_SLIDE_STATUS>,
  overContainer: string | null,
  slideCount: Record<string, number>,
  onScrollToStatus: (status: MATCH_SLIDE_STATUS) => void,
  setOverContainer: React.Dispatch<React.SetStateAction<string|null>>,
}

// keep the count in the state machine for the header

export interface ProviderProps {
  children: ReactNode
}

export const SlideGroupTargetsContext = createContext<SlideGroupTargetsContextType>(null!);
SlideGroupTargetsContext.displayName = 'SlideGroupTargetsContext';

const SlideGroupTargetsStateProvider: React.FC<PropsWithChildren<ProviderProps>> = ({
  children,
}) => {
  const {
    mappedValues,
  } = usePublisherVersioningState();
  const { slideGroupTargetRef } = useMatchSlidesState()
  const { groupedTargetItems } = useSingleItemDnd();
  const [overContainer, setOverContainer] = useState<string|null>(null)

  const onScrollToStatus = useCallback((status: MATCH_SLIDE_STATUS) => {
    const indexToScrollTo = Object.entries(mappedValues).findIndex(([pageId]) => {
      const mappedItem = groupedTargetItems[pageId]?.[0]?.itemId || null
      const isBelowThreshold =
        mappedItem &&
        mappedValues[pageId]?.recommendations[0]?.score < SCORE_THRESHOLD
      const needsReview = mappedItem && mappedValues[pageId] && !mappedValues[pageId].mapping && isBelowThreshold
      const userUnmatched = mappedValues[pageId] && mappedValues[pageId].mapping === 'USER_UNMATCHED'
      if (status === MATCH_SLIDE_STATUS.NEEDS_REVIEW) {
        return (needsReview)
      } else if (status === MATCH_SLIDE_STATUS.NO_MATCH) {
        return (!mappedItem || userUnmatched )
      }
    })
    if (indexToScrollTo < 0) return
    slideGroupTargetRef.current?.scrollToIndex(indexToScrollTo, true)
  }, [mappedValues, groupedTargetItems])

  const matchSlideIconStates = useMemo(() => {
    return Object.keys(mappedValues).reduce<Record<string, MATCH_SLIDE_STATUS>>((acc, pageId) => {
      const mappedItem = groupedTargetItems[pageId]?.[0]?.itemId || null
      const isBelowThreshold =
        mappedItem &&
        mappedValues[pageId]?.recommendations[0]?.score < SCORE_THRESHOLD
      const needsReview = mappedItem && mappedValues[pageId] && !mappedValues[pageId].mapping && isBelowThreshold
      const hasMapping =
        mappedValues[pageId] &&
        mappedValues[pageId].mapping &&
        mappedValues[pageId].mapping !== 'USER_UNMATCHED'
      const hasMatching = mappedItem || hasMapping
      if (needsReview) {
        acc[pageId] = MATCH_SLIDE_STATUS.NEEDS_REVIEW
      } else if (hasMatching) {
        acc[pageId] = MATCH_SLIDE_STATUS.MATCHED
      } else {
        acc[pageId] = MATCH_SLIDE_STATUS.NO_MATCH
      }
      return { ...acc }
    }, {})
  }, [mappedValues, groupedTargetItems])

  const slideCount = Object.entries(mappedValues).reduce<Record<string, number>>((acc, [pageId]) => {
    const needsReview = matchSlideIconStates[pageId] === MATCH_SLIDE_STATUS.NEEDS_REVIEW
    const noMatching = matchSlideIconStates[pageId] === MATCH_SLIDE_STATUS.NO_MATCH
    if (needsReview) {
      acc.needsReview = acc.needsReview + 1
    } else if (noMatching) {
      acc.noMatch = acc.noMatch + 1
    }
    acc.total = acc.total + 1
    return acc
  }, { noMatch: 0, needsReview: 0, total: 0 })

  const context: SlideGroupTargetsContextType = {
    matchSlideIconStates,
    overContainer,
    slideCount,

    // fucntions
    onScrollToStatus,
    setOverContainer,
  };

  return (
    <SlideGroupTargetsContext.Provider value={context}>
      {children}
    </SlideGroupTargetsContext.Provider>
  );
};

SlideGroupTargetsStateProvider.displayName = 'SlideGroupTargetsStateProvider';

export const useSlideGroupTargetsState = () => {
  const context = useContext(SlideGroupTargetsContext);
  if (!context) {
    throw new Error('useSlideGroupTargetsState must be used within the SlideGroupTargetsStateProvider');
  }
  return context;
};

export default SlideGroupTargetsStateProvider;
