import React, {
  createContext,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useContext,
  useRef,
} from 'react';
import { DNABox, DNAButton, DNAText } from '@alucio/lux-ui';
import DNAError from 'src/components/DNA/Error';
import { usePublisherVersioningState } from '../PublisherVersioningProvider';
import { RecyclerListView } from 'recyclerlistview';
import { RecyclerListViewState } from 'recyclerlistview/dist/reactnative/core/RecyclerListView';
import { RViewProps } from 'src/screens/Publishers/Versioning/MatchSlides/SlidesGroupPool';
import { DocumentVersionORM } from 'src/types/orms';
import { MatchSlidesService } from './matchSlides.types';
import useMachineSelector, { composite } from 'src/hooks/useSelector';
import * as matchSlidesSelector from 'src/state/machines/publisherVersioning/MatchSlides/matchSlides.selector';
import logger from 'src/utils/logger';

export interface MatchSlidesContextType {
  service: MatchSlidesService,
  slideGroupPoolRef: React.RefObject<RecyclerListView<RViewProps, RecyclerListViewState>>,
  slideGroupTargetRef: React.RefObject<RecyclerListView<RViewProps, RecyclerListViewState>>,
  currentDocumentVersionORM: DocumentVersionORM,
  latestPublishedDocumentVersionORM: DocumentVersionORM,
  toggleSlidesGroupPoolVisible: () => void,
  onUnmatch: (payload: string) => void,
  onMatch: (pageId: string, mapping: string) => void,
  onScrollToIndexInSlideGroupPool: (index: number) => void,
}

export interface ProviderProps {
  children: ReactNode
}

export const MatchSlidesContext = createContext<MatchSlidesContextType>(null!);
MatchSlidesContext.displayName = 'MatchSlidesContext';

const MatchSlidesStateProvider: React.FC<PropsWithChildren<ProviderProps>> = ({

  children,
}) => {
  const {
    service: publisherVersioningService,
    setLatestDocumentVersionContentPageData,
    currentDocumentVersionORM,
    latestPublishedDocumentVersionORM,
    documentORM,
    toggleSlider,
  } = usePublisherVersioningState();

  /** STATE MACHINE */
  const service = useMachineSelector(
    publisherVersioningService,
    ctx => ctx.context.matchSlidesActor,
  ) as MatchSlidesService

  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      matchSlidesSelector.slidesGroupPoolVisible,
    ),
  )

  const setSlidesGroupPoolVisible = useCallback((slidesGroupPoolVisible: boolean) => {
    service.send({ type: 'SET_SLIDES_POOL_VISIBLE', payload: slidesGroupPoolVisible })
  }, [service])

  const toggleSlidesGroupPoolVisible = useCallback((): void => {
    setSlidesGroupPoolVisible(!cond.slidesGroupPoolVisible);
  }, [setSlidesGroupPoolVisible, cond.slidesGroupPoolVisible])

  const slideGroupPoolRef = useRef<RecyclerListView<RViewProps, RecyclerListViewState>>(null);
  const slideGroupTargetRef = useRef<RecyclerListView<RViewProps, RecyclerListViewState>>(null);

  const onScrollToIndexInSlideGroupPool = useCallback((index: number) => {
    if (!cond.slidesGroupPoolVisible) setSlidesGroupPoolVisible(true)
    setTimeout(() => {
      slideGroupPoolRef.current?.scrollToIndex(index, true)
    }, 500)
  }, [cond.slidesGroupPoolVisible, setSlidesGroupPoolVisible])

  /**
    * This functions unmatches slides based on the input
    * @param payload payload is a string, if string is pageId one slide is unmatched, if payload is all, the entire document is unmatched
  */
  const onUnmatch = useCallback((payload: string) => {
    publisherVersioningService.send({ type: 'SET_IS_DIRTY', payload: { type: 'slidesData', isDirty: true } })
    if (payload === 'all') {
      setLatestDocumentVersionContentPageData(preState => {
        return preState.map(pageData => ({ ...pageData, mapping: 'USER_UNMATCHED' }))
      })
    } else {
      setLatestDocumentVersionContentPageData(preState => {
        return preState.map(pageData => {
          if (pageData.pageId === payload) {
            return { ...pageData, mapping: 'USER_UNMATCHED' }
          } else {
            return pageData
          }
        })
      })
    }
  }, [publisherVersioningService, setLatestDocumentVersionContentPageData])

  /**
    * This functions matches the new and previous page id
    * @param pageId is the new version page id
    * @param mapping is the previous mapping the new id is getting mapped to
  */
  const onMatch = useCallback((pageId: string, mapping: string) => {
    publisherVersioningService.send({ type: 'SET_IS_DIRTY', payload: { type: 'slidesData', isDirty: true } })
    setLatestDocumentVersionContentPageData(preState => {
      return preState.map(pageData => {
        if (pageData.pageId === pageId) {
          return { ...pageData, mapping: mapping }
        } else {
          return pageData
        }
      })
    })
  }, [publisherVersioningService, setLatestDocumentVersionContentPageData])

  if (!latestPublishedDocumentVersionORM) {
    const errorText = `Could not find latestPublishedDocumentVersionORM: ${documentORM.model.id}`;
    logger.versioning.error(errorText);
    return (
      <DNABox
        fill
        appearance="col"
        alignX="center"
        alignY="center"
        style={{ backgroundColor: 'white' }}
      >
        <DNAError
          promptLogout={false}
          message="Could not open Document for versioning!"
        >
          <DNAText>{documentORM.model.id}</DNAText>
          <DNAButton
            appearance="ghost"
            onPress={() => toggleSlider()}
          >
            Go back
          </DNAButton>
        </DNAError>
      </DNABox>
    )
  }

  const context: MatchSlidesContextType = {
    slideGroupPoolRef,
    slideGroupTargetRef,
    service,
    currentDocumentVersionORM,
    latestPublishedDocumentVersionORM,
    // FUNCTIONS
    toggleSlidesGroupPoolVisible,
    onUnmatch,
    onMatch,
    onScrollToIndexInSlideGroupPool,
  };

  return (
    <MatchSlidesContext.Provider value={context}>
      {children}
    </MatchSlidesContext.Provider>
  );
};

MatchSlidesStateProvider.displayName = 'MatchSlidesStateProvider';

export const useMatchSlidesState = () => {
  const context = useContext(MatchSlidesContext);
  if (!context) {
    throw new Error('useMatchSlidesState must be used within the MatchSlidesStateProvider');
  }
  return context;
};

export default MatchSlidesStateProvider;
