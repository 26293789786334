// [TODO-ASSOCIATED] - Rename (Removed -> Remove)
import React from 'react';
import { ScrollView } from 'react-native';
import { DNABox, DNAButton, DNASlider } from '@alucio/lux-ui';
import DNAThumbnail from 'src/components/DNA/Thumbnail/DNAThumbnail';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { Page } from '@alucio/aws-beacon-amplify/src/models';
import useMachineSelector, { composite } from 'src/hooks/useSelector';
import * as slideSettingsSelector from 'src/state/machines/publisherVersioning/SlideSettings/slideSettings.selectors';
import { useSlideSettingsState } from 'src/state/machines/publisherVersioning/SlideSettings/SlideSettingsProvider';
import { SlideSettingsStateProvider, useSlideSettingsState as useSlideSettingsStateProxy }
  from 'src/state/machines/publisherVersioning/SlideSettings/SlideSettingsProvider.proxy';
import { getThumbURL } from 'src/utils/thumbnailHelpers';
import AssociatedSlidesThumbnails from 'src/components/DNA/Thumbnail/AssociatedSlidesThumbnails';
import ModalHeader from '../ModalHeader';
import { styles } from '../SlideSettings';

interface SlideProps {
  page: Page
}

const Slide: React.FC<SlideProps> = (props) => {
  const { page } = props;
  const {
    service,
    currentDocumentVersionORM,
    thumbnailSizes,
    pageMap,
    selectAllAssociatedSlidesToRemove,
    selectAssociatedSlidesToRemove,
  } = useSlideSettingsStateProxy()

  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      slideSettingsSelector.versionDraft,
      slideSettingsSelector.selectedRemoveAssociatedSlides,
    ),
  )

  const coverThumbnailPageNumber = cond.versionDraft.selectedThumbnail ?? 1
  const thumbURL = getThumbURL(currentDocumentVersionORM, page.number)
  const isSelected = page.number === coverThumbnailPageNumber
  const associatedPages = Object
    .values(cond.selectedRemoveAssociatedSlides?.[page.pageId] ?? {})
    ?.map(linkedSlideId => pageMap[linkedSlideId]) ?? []

  return (
    <DNABox
      key={page.pageId}
      appearance="col"
      spacing="sm"
      childFill={1}
      style={{ marginRight: 12, marginBottom: 24 }} // this is to replace the spacing on the parent element, because spacing does not apply on the last item
    >
      {/* [TODO-ASSOCIATED] - Figure out a better way to avoid shifting scrollbar */}
      <DNABox style={{ marginRight: 12 }}>
        <DNAThumbnail
          key={thumbURL}
          s3URL={thumbURL}
          useLoadingIndicator
          size={thumbnailSizes.thumbnailSize}
          mode={DNAThumbnail.Modes.READ_ONLY}
          variant={DNAThumbnail.Variants.INFO}
          pageNumber={page.number}
          isCover={isSelected}
          isRequired={page.isRequired}
        />
      </DNABox>
      <AssociatedSlidesThumbnails
        mode={AssociatedSlidesThumbnails.Mode.REMOVABLE}
        docVerORM={currentDocumentVersionORM}
        pages={associatedPages}
        size={thumbnailSizes.thumbnailSize}
        onRemoveAll={() => selectAllAssociatedSlidesToRemove(page.pageId)}
        onRemove={(pageId) => selectAssociatedSlidesToRemove(page.pageId, pageId)}
      />
    </DNABox>
  )
}

const RemoveAssociatedSlidesModal: React.FC = () => {
  const slideSettingsState = useSlideSettingsState()
  const {
    service,
    thumbnailSizes,
    backToIdle,
    saveRemoveAssociatedSlides,
  } = slideSettingsState

  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      slideSettingsSelector.versionDraft,
      slideSettingsSelector.isRemoveAssociatedSlidesMode,
    ),
  )

  return (
    <DNASlider
      visible={cond.isRemoveAssociatedSlidesMode}
      setVisible={backToIdle}
      orientation="horizontal"
    >
      <SlideSettingsStateProvider value={slideSettingsState}>
        <DNABox
          fill
          appearance="col"
          style={{ backgroundColor: colors['color-text-white'] }}
        >
          <ModalHeader
            title="Remove associated slides"
            onSave={saveRemoveAssociatedSlides}
            onCancel={backToIdle}
          />
          {/* CONTENT */}
          <DNABox fill>
            <DNABox fill appearance="col">
              <DNABox style={styles.rowWrapper} >
                <DNABox fill alignX="end">
                  <DNAButton
                    appearance="ghostLink"
                    status="tertiary"
                    size="md"
                    padding="none"
                    onPress={thumbnailSizes.cycleThumbnailSize}
                    iconLeft={thumbnailSizes.thumbnailSize === 'lg' ? 'view-comfy' : 'view-grid'}
                  />
                </DNABox>
              </DNABox>
              <DNABox fill>
                <ScrollView style={styles.content}>
                  <DNABox
                    appearance="row"
                    wrap="start"
                    childStyle={{ marginBottom: 32 }}
                    alignX="center"
                  >
                    {cond.versionDraft.pages?.map((page) => <Slide page={page} />)}
                  </DNABox>
                </ScrollView>
              </DNABox>
            </DNABox>
          </DNABox>
        </DNABox>
      </SlideSettingsStateProvider>
    </DNASlider>
  )
}

export default RemoveAssociatedSlidesModal
