import React from 'react';
import { StyleSheet } from 'react-native';
import { DNABox } from '@alucio/lux-ui';

import DNAThumbnail from 'src/components/DNA/Thumbnail/DNAThumbnail';
import { SortableItem } from 'src/components/DnD/DnDWrapper';

import { useCloneBuilder, POOL_CONTAINER_ID } from 'src/components/DnD/Clone/CloneBuilder';
import { getThumbURL } from 'src/utils/thumbnailHelpers';
import { useSlideSettingsState }
  from 'src/state/machines/publisherVersioning/SlideSettings/SlideSettingsProvider.proxy';
import { useGroupState }
  from 'src/screens/Publishers/Versioning/SlideSettings/Modals/SetGroupsModal/GroupProvider.proxy';

const S = StyleSheet.create({
  dragging: {
    opacity: 0.3,
  },
  pageContainer: {
    marginBottom: 16,
  },
  itemStyle: {
    alignSelf: 'flex-start',
  },
})

const SlidesGroupPool: React.FC = () => {
  // [NOTE] - Doesn't necessary have to use cloneBuilder, could also reference the state directly
  const { activeId, poolItems, activeContainerOrigin } = useCloneBuilder()
  const {
    currentDocumentVersionORM,
    thumbnailSizes,
    getSlideTitle,
    selectGroupSlide,
  } = useSlideSettingsState()
  const { selectedPagesMap, pagesMap } = useGroupState()

  return (
    <DNABox
      testID="slide-pool-container"
      appearance="row"
      wrap="start"
      spacing="md"
      childStyle={S.pageContainer}
      alignX="center"
    >
      {
        poolItems.map(({ id, itemId }) => (
          <SortableItem
            key={itemId}
            id={id}
            itemId={itemId}
            containerId={POOL_CONTAINER_ID}
            style={
              StyleSheet.flatten([
                (activeId && activeContainerOrigin.current === POOL_CONTAINER_ID &&
                selectedPagesMap[itemId])
                  ? S.dragging
                  : undefined,
                S.itemStyle,
              ])
            }
          >
            <DNABox appearance="col" spacing="sm" fill>
              <DNAThumbnail
                s3URL={getThumbURL(currentDocumentVersionORM, pagesMap[itemId]?.number ?? 0)}
                useLoadingIndicator
                size={thumbnailSizes.thumbnailSize}
                checked={selectedPagesMap[itemId]}
                mode={DNAThumbnail.Modes.SELECTABLE}
                variant={DNAThumbnail.Variants.INFO}
                thumbnailTitle={getSlideTitle(pagesMap[itemId]?.number ?? 0)}
                pageNumber={pagesMap[itemId]?.number ?? 0}
                onCheck={() => selectGroupSlide(itemId)}
              />
            </DNABox>
          </SortableItem>
        ))
      }
    </DNABox>
  )
}

export default SlidesGroupPool
