import React from 'react';
import { StyleSheet } from 'react-native';
import { DNABox, luxColors } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import SlideSettingsStateProvider
  from 'src/state/machines/publisherVersioning/SlideSettings/SlideSettingsProvider';
import Header from './Header';
import Content from './Content/Content';
// MODALS
import AddAssociatedSlidesModal from './Modals/AddAssociatedSlidesModal';
import RemoveAssociatedSlidesModal from './Modals/RemoveAssociatedSlidesModal';
import SetCoverThumbnailModal from './Modals/SetCoverThumbnailModal';
import SetRequiredSlidesModal from './Modals/SetRequiredSlidesModal';
import SetGroupsModal from './Modals/SetGroupsModal/SetGroupsModal';

export const styles = StyleSheet.create({
  headerBar: {
    padding: 16,
    borderBottomColor: colors['color-gray-80'],
    borderBottomWidth: 1,
  },
  content: {
    paddingHorizontal: 32,
    paddingVertical: 32,
  },
  rowWrapper: {
    height: 50,
    paddingHorizontal: 22,
    paddingVertical: 12,
    borderBottomColor: colors['color-gray-80'],
    borderBottomWidth: 1,
  },
  verticalDivider: {
    backgroundColor: luxColors.headerTopColor.primary,
    height: 20,
    width: 1,
  },
});

const SlideSettings: React.FC = () => {
  return (
    <DNABox fill appearance="col">
      {/* ACTION BAR */}
      <Header />
      {/* CONTENT */}
      <Content />
      {/* MODES */}
      <AddAssociatedSlidesModal/>
      <SetCoverThumbnailModal/>
      <SetRequiredSlidesModal/>
      <RemoveAssociatedSlidesModal/>
      <SetGroupsModal />
    </DNABox>
  )
}

const SlideSettingsWrapper: React.FC = () => {
  return (
    <SlideSettingsStateProvider>
      <SlideSettings />
    </SlideSettingsStateProvider>
  )
}

export default SlideSettingsWrapper
